//
//
//
//
//

function asyncLoadJs(url) {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        document.body.appendChild(script);
        script.onload = () => {
            resolve();
        }
        script.onerror = () => {
            reject();
        }
    })
}

asyncLoadJs("https://map.qq.com/api/gljs?v=1.exp&key=TVCBZ-A4EC3-RIJ3Q-3ZEVT-DSUF5-33FAE");

export default {
    name: "StoreManagement"
}
